// 影像
<template>
  <div>
    <TabPageLayout activeCode="yujianyizhong">
      <div class="container videos" id="anchor">
        <div class="div_mod div_mod1">
          <!-- 栏目描述 -->
          <div class="div_text" v-html="activeTabBz"></div>
          <div class="" style="height: 50px; clear: both; position: relative">
            <el-select
              v-model="currentType"
              class="div_chooseBox"
              value-key=""
              placeholder="选择视频类型"
              clearable
              @change="handleVideoTypeChange"
            >
              <el-option
                v-for="item in videoType"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>

          <div class="div_video">
            <div class="swiper-container swiper_imgs">
              <div class="swiper-wrapper">
                <div
                  class="swiper-slide"
                  v-for="video in videos"
                  :key="video.id"
                >
                  <div
                    class="video_cover"
                    :style="{
                      display: currentVideoId === video.id ? 'none' : 'block',
                    }"
                    url=""
                    @click="handleCoverClick(video)"
                  >
                    <img :src="video.videoImg" alt="" />
                    <p>{{ video.title }}</p>
                  </div>
                  <div
                    class="video_box"
                    :style="{
                      display: currentVideoId === video.id ? 'block' : 'none',
                    }"
                  >
                    <video
                      :ref="`video_${video.id}`"
                      controls="controls"
                      :src="video.videoUrl"
                      id=""
                    ></video>
                  </div>
                </div>
              </div>
            </div>

            <div class="div_icons">
              <div class="swiper-container swiper_icons">
                <div class="swiper-wrapper">
                  <div
                    class="swiper-slide"
                    v-for="video in videos"
                    :key="video.id"
                  >
                    <img :src="video.videoImg" alt="" />
                  </div>
                </div>
              </div>
              <div class="direction">
                <span class="s_prev"><i></i></span>
                <span class="s_next"><i></i></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </TabPageLayout>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import TabPageLayout from './components/TabPageLayout.vue'
import portalApi from '../../../lvya-common/src/api/eos/portal'
import { useAddColumnCount } from './utils/useAddArticleCount'

export default {
  components: { TabPageLayout },
  data() {
    return {
      videoType: {},
      currentType: '',
      videos: [],
      currentVideoId: '',
    }
  },
  computed: {
    ...mapState({
      nav: (state) => state.yzsyStore.nav,
    }),
    navTabs() {
      return (
        this.nav
          ?.find((item) => item.code === 'yujianyizhong')
          ?.stypes?.map((nav) => ({
            id: nav.id,
            name: nav.title,
            path: nav.tzurl,
            bz: nav.bz,
          })) || []
      )
    },
    sceneryId() {
      let sceneryId = ''
      this.nav
        ?.find((item) => item.code === 'yujianyizhong')
        ?.stypes?.forEach((nav) => {
          console.log(nav)
          if (nav.code === 'yizhongyingxiang') {
            sceneryId = nav.id
          }
        }) || []
      return sceneryId
    },
    activeTabBz() {
      console.log(this.navTabs?.find((item) => item.path === this.$route.path), 'activeTabBz')
      return this.navTabs?.find((item) => item.path === this.$route.path)?.bz
    }
  },
  methods: {
    async handleCoverClick(video) {
      this.currentVideoId = video.id
      if (video.videoUrl) {
        this.$refs[`video_${video.id}`][0].play()
      } else {
        console.log('无可播放视频')
      }
      // 增加友情链接访问量
      await portalApi.addCount({ noSchoolId: true, id: video.id, type: '5', })
    },
    async getVideoType() {
      this.portalSchoolInfo = JSON.parse(
        localStorage.getItem('portalSchoolInfo')
      )
      let res = { type: 0 }
      let resData = await portalApi.getLinkTypeList(res)
      console.log({ resData })
      this.videoType = resData.map((item) => ({
        label: item.title,
        value: item.id,
      }))
      this.currentType = this.videoType[0].value
      this.getVideos(this.currentType)
    },
    async handleVideoTypeChange(value) {
      // this.currentType = value
      console.log({ value })
      this.getVideos(value)
      useAddColumnCount(value)
    },
    async getVideos(typeId) {
      const reqData = {
        type: 0,
        typeId: typeId,
      }
      const resData = await portalApi.getLinkListNoRole(reqData)
      console.log(resData)
      this.videos = resData
    },
  },
  async mounted() {
    console.log(this.nav, 'navnavnavnavnav')
    await this.getVideoType()
    await this.getVideos()
    let activeSlide
    new Swiper('.div_video .swiper_imgs', {
      spaceBetween: 0,
      loop: false,
      navigation: {
        nextEl: '.div_video .s_next',
        prevEl: '.div_video .s_prev',
      },
      on: {
        init: function(swiper) {
          activeSlide = this.activeIndex
        },
        slideChange: function() {
          // $(".swiper_imgs").find(".video_cover").show();
          // $(".swiper_imgs").find(".video_box").hide();
          const preSlide = $('.div_video .swiper-slide').eq(activeSlide)
          preSlide.find('video').trigger('pause')
          activeSlide = this.activeIndex
        },
      },
      thumbs: {
        swiper: {
          el: '.div_video .div_icons .swiper_icons',
          spaceBetween: 10,
          slidesPerView: 5,
          watchSlidesVisibility: true,
        },
        autoScrollOffset: 0,
      },
    })
  },
}
</script>

<style>
</style>
